// import t1 from "../images/t1.avif"
// import t2 from '../images/t2.avif'
// import t3 from '../images/t3.avif'



export const TestimonilsData =[
    {
        image:"../images/t1.avif" ,
        review:
        "I made the right choice by choosing the fitnesss",
        name:'  JACKSON',
        status: 'ENTERPRENEUR'
    },
    {
        image: "../images/t2.avif",
        review:
        "this is so good for me excellent trainers and coches",
        name:'ALLENA',
        status: 'ACTRESS'
    },
    {
        image: "../images/t3.avif",
        review:
        "This is best gym for the everyone right choice by choosing the fitnesss",
        name:'  METHEW HENDRICKOSN',
        status: 'ACTOR'
    },
  
]