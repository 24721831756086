import React from "react";
import "./Reasons.css";
import {FiCheckCircle} from 'react-icons/fi'

const Reasons = () => {
  return (
    <div className="Reasons" id="reasons">
      <div className="left-r">
        <img src="../images/reasone1.jpeg" alt="" />
        {/* <img src="../images/hero.avif" alt="" /> */}
        {/* <img src="../images/reasone3.jpg" alt="" /> */}
        {/* <img src="../images/reasone4.jpg" alt="" /> */}
      </div>
      <div className="right-r">
        <span>some reasons</span>
        <div>
          <span className="stroke-text">Why</span>
          <span>choose us?</span>
        </div>
        <div className="details-r">
            <div>
                <FiCheckCircle className="icons"/>
                <span>OVER 140+ EXPERT COACHS</span>
            </div>
            <div>
            <FiCheckCircle className="icons"/>
                <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
            </div>
            <div>
            <FiCheckCircle className="icons"/>
                <span>1 FREE PROGRAM FROM NEW MEMBER</span>
            </div>
            <div>
            <FiCheckCircle className="icons"/>
                <span>RELIABLE PARTNERS</span>
            </div>
        </div>
        <span style={{color:"var(--gray)",fontWeight:"normal"}}>OUR PARTNERS</span>
        <div className="partners">
            <img src="../images/p1.png"/>
            <img src="../images/p2.png"/>
            <img src="../images/p3.png"/>
        </div>
      </div>
    </div>
  );
};

export default Reasons;
