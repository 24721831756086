import React from "react";
import "./Plans.css";
import { TbSunHigh } from "react-icons/tb";
import { FiCheckCircle } from "react-icons/fi";
import {FaCrown} from 'react-icons/fa'
import {RiHeartsLine} from 'react-icons/ri'


const Plans = () => {
  return (
    <div className="plans-containers">
      <div className="blur plans-blur-1"></div>
      <div className="blur plans-blur-2"></div>
      <div className="programs-header">
        <span className="stroke-text">Ready To start </span>
        <span>Your Journey</span>
        <span className="stroke-text">Now Withus</span>
      </div>
      {/* Plans Card */}
      <div className="plans">
        <div className="plan">
       <RiHeartsLine className="img"/>
          <span>BASIC PLAN </span>
          <span> $25 </span>
          <div className="features">
            <div className="feature">
              <div>
                <FiCheckCircle className="icons" />
                <span>2 hours of excerise</span>
              </div>
              <div>
                <FiCheckCircle className="icons" />
                <span>Free consultation</span>
              </div>
              <div>
                <FiCheckCircle className="icons" />
                <span>Access to The Community</span>
              </div>
            </div>
          </div>
          <div>
            <span>See more benefits </span>
          </div>
          <button className="btn">Join Now</button>
        </div>
        <div className="plan">
         <FaCrown className="img"/>
          <span>PREMIUM PLAN </span>
          <span> $30 </span>
          <div className="features">
            <div className="feature">
              <div>
                <FiCheckCircle className="icons" />
                <span>5 hours of excerise</span>
              </div>
              <div>
                <FiCheckCircle className="icons" />
                <span>Free consultation Of Coaches</span>
              </div>
              <div>
                <FiCheckCircle className="icons" />
                <span>Access to Minibar</span>
              </div>
            </div>
          </div>
          <div>
            <span>See more benefits </span>
          </div>
          <button className="btn">Join Now</button>
        </div>
        <div className="plan">
          <TbSunHigh className="img"/>
          <span>PRO PLAN </span>
          <span> $45 </span>
          <div className="features">
            <div className="feature">
              <div>
                <FiCheckCircle className="icons" />
                <span>8 hours of excerise</span>
              </div>
              <div>
                <FiCheckCircle className="icons" />
                <span>Free consultation Of Private Coaches</span>
              </div>
              <div>
                <FiCheckCircle className="icons" />
                <span>Access to Minibar</span>
              </div>
            </div>
          </div>

          <div>
            <span>See more benefits </span>
          </div>
          <button className="btn">Join Now</button>
        </div>
      </div>
    </div>
  );
};

export default Plans;
