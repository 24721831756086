import React, { useState } from "react";
import "./Header.css";
import { AiOutlineBars } from "react-icons/ai";
import { Link } from "react-scroll/modules";

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false);
  return (
    <div className="header" >
      <img className="nav_logo" src="../images/logo.jpg" />
      {menuOpened === false && mobile === true ? (
        <div
          style={{
            backgroundColor: " var(--appColor)",
            padding: "0.5rem",
            borderRadius: "5px",
          }}
          onClick={() => setMenuOpened(true)}
        >
          <AiOutlineBars
            style={{ width: "1.5rem", height: "1.5rem", color: "#fff" }}
          />
        </div>
      ) : (
        <ul className="header_menu" style={{position:"sticky"}}>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              activeClass="active"
              to="home"
              smooth={true}
              span={"true"}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to="programs"
              smooth={true}
              span={"true"}
            >
              Programs
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to="reasons"
              smooth={true}
              span={"true"}
            >
              Why Us
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to="plans"
              smooth={true}
              span={"true"}
            >
              Plans
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to="testimonials"
              smooth={true}
              span={"true"}
            >
              Testimonils
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
