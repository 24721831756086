import React from "react";
import "./Program.css";
import { CgArrowRight, CgGym } from "react-icons/cg";
import { BsHeartPulse, BsFire } from "react-icons/bs";
import { BiRun } from "react-icons/bi";

const Program = () => {
  return (
    <div className="programs" id="programs">
      {/* Header */}
      <div className="programs-header">
        <span className="stroke-text">Explore our </span>
        <span>Programs</span>
        <span className="stroke-text">to shape you</span>
      </div>
      <div className="program-categories">
        <div className="category">
          <CgGym />
          <span>Strength Traning</span>
          <span>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad, veniam?
          </span>{" "}
          <div className="join-now">
            <span>
              Join Now <CgArrowRight className="arrow" />
            </span>
          </div>
        </div>
        <div className="category">
          <BiRun />
          <span>Cardio Traning</span>
          <span>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad, veniam?
          </span>{" "}
          <div className="join-now">
            <span>
              Join Now <CgArrowRight className="arrow"/>
            </span>
          </div>
        </div>
        <div className="category">
          <BsFire />

          <span>Fat Burning</span>
          <span>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad, veniam?
          </span>
          <div className="join-now">
            <span>
              Join Now <CgArrowRight className="arrow" />
            </span>
          </div>
        </div>
        <div className="category">
          <BsHeartPulse />
          {/* <img src="" alt="img-1" /> */}
          <span>Health Fitness</span>
          <span>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad, veniam?
          </span>
          <div className="join-now">
            <span>
              Join Now <CgArrowRight className="arrow" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Program;
