import React from "react";
import "./Footer.css";
import { BsInstagram } from "react-icons/bs";
import { AiOutlineYoutube } from "react-icons/ai";
import { FiGithub } from "react-icons/fi";

const Footer = () => {
  const CurrentTime = new Date().getFullYear();
  return (
    <>
      <hr />
      <div className="footer-container">
        {/* <div className="footer">
        <div className="social-link">
        <BsInstagram className="icons"/>
        <AiOutlineYoutube className="icons"/>
        <FiGithub className="icons"/>
        </div>
      </div>
      <div className="logo-f">
     
      </div>
      <div className="blur blur-f-1"></div>
      <div className="blur blur-f-2"></div> */}
        <div className="left-f">
          <div className="deatils-f">
            <span className="stroke-text">BEE - FIT</span>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti,
              nam!
            </span>
          </div>
        </div>
        <div className="right-f">
          <div className="footer">
            <div className="social-link">
           
              <BsInstagram className="icons" />
              <AiOutlineYoutube className="icons" />
              <FiGithub className="icons" />
            </div>
          </div>
          <div className="logo-f"></div>
          <div className="blur blur-f-1"></div>
          <div className="blur blur-f-2"></div>
        </div>
      </div>
      <div className="copy-right">
        <p> BEE-FIT &copy; {CurrentTime} </p>{" "}
      </div>
    </>
  );
};

export default Footer;
