import logo from './logo.svg';
import './App.css';
import Hero from './Component/Hero/Hero';
import Program from './Component/Programs/Program';
import Reasons from './Component/Reasons/Reasons';
import Plans from './Component/Plans/Plans';
import Testimonils from './Component/Testimonils/Testimonils';
import Join from './Component/Join/Join';
import Footer from './Component/Footer/Footer';


function App() {
  return (
<div className='App'>
  <Hero/>
  <Program/>
  <Reasons/>
  <Plans/>
  <Testimonils/>
  <Join/>
  <Footer/>
</div>
  );
}

export default App;
